import React from 'react'
import Form from "react-bootstrap/Form";
import Logout from '../../Images/Group 1948757323.png';
import { Link } from 'react-router-dom';

function AccountInformation() {
  return (
    <div className='p-0 m-0'>
       <div className="inner-shadow p-4 pb-5">
        <h3 className="overview-heading">Logout</h3>
        <center><img src={Logout} alt="" /></center>
          <p className='mt-4'></p>
          <center>
          <button className='update-account-cancel'>Cancel</button>
          
          <Link to="/login"><button className='update-account ms-2'>Log out</button> </Link>
          </center>
                
      </div>
    </div>
  )
}

export default AccountInformation