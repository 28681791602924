import React from "react";
import Header from "../Header/Index";
import Footer from '../Footer/Index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ConfidentImage from "../../Images/confident-male-plumber-with-toolbox-pipe-wrench-isolated-white-transparent-background-realist 1 (1).png";
function Index() {
  return (
    <div>
      <div className="product-details">
        <Header />
        <div className="container pb-5">
          <h1 className="shop-catergory-h1 pt-5">About us</h1>
          <p className="shop-catergory-p">
            Home{" "}
            <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "12px" }} />{" "}
            <span style={{ color: "red" }}>About us</span>
          </p>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-4">
            <img src={ConfidentImage} alt="" className="about-us-vector" />
          </div>
          <div className="col-md-6 left-side-border">
            <h3 className="overview-heading" style={{ fontWeight: "bold" }}>
              About Us{" "}
            </h3>
            <p className="pt-4">
              Get Plumb is a family-run business. We are a core team of
              individuals who have collectively been in the industry for 35+
              years. Throughout the years, we have built a loyal customer base
              achieved through our hard work, reliability, and trust.
            </p>
            <p>
              Although we are based in Reading, we thrive to support our clients
              based pretty much anywhere within England. Just get in touch and
              we will see how to make mountains move in order to support you.
            </p>
            <p>
              At Get Plumb, we pride ourselves on the technical knowledge and
              only supply products of the highest quality and at trade prices.
              These products can range from everything you need to complete your
              bathroom project to providing supplies for your home’s water
              filtration system.
            </p>
            <p>
              Get in contact to see how we can either help in your next small
              renovation project or help with fixing that leaky sink! We would
              like you to entrust us as your local, reliable trade heating &
              plumbing company.
            </p>
            <p>
              <b>Why choose us?</b>
            </p>
            <p>
              Being a family-run business, we thrive on providing the best
              products and customer service to our Customers. We are small
              enough to have a chat to listen and understand what you need -
              providing a very customized experience; yet we have the industry
              experience to be able to offer you with advice and
              recommendations.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
