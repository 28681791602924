import React from "react";
import Header from "../Header/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from '../Footer/Index';


function Index() {
   
        const [verified, setVerified] = useState(false);
      
        // Function to handle successful reCAPTCHA
        const handleRecaptcha = (value) => {
          console.log("Captcha value:", value);
          if (value) {
            setVerified(true);
          }
        };
      
        const handleSubmit = () => {
          if (verified) {
            alert("Verification successful. Form submitted!");
            // You can now process your form submission logic here
          } else {
            alert("Please complete the CAPTCHA verification");
          }
        };
  return (
    <div style={{background:"#F4F4F4"}}>
      <div className="product-details">
        <Header />
        <div className="container pb-5">
          <h1 className="shop-catergory-h1 pt-5">Contact Us</h1>
          <p className="shop-catergory-p">
            Home{" "}
            <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "12px" }} />{" "}
            <span style={{ color: "red" }}>Contact Us</span>
          </p>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8 contect-us-left mt-5">
            <div className="inner-shadow p-4">
              <h3 className="overview-heading">
              Contact Us
              </h3>
              <p>Please use the contact form below to get in touch with us.</p>
              <div className="row">
                <div className="col-md-6 ">
                  <Form.Label
                    htmlFor="inputPassword5"
                    className="form-label mt-4"
                  >
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className="form-input mt-1"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label
                    htmlFor="inputPassword5"
                    className="form-label mt-4"
                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className="form-input mt-1"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label
                    htmlFor="inputPassword5"
                    className="form-label mt-4"
                  >
                    Email Address
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className="form-input mt-1"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label
                    htmlFor="inputPassword5"
                    className="form-label mt-4"
                  >
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    className="form-input mt-1"
                  />
                </div>
                <div className="col-md-12">
                  <Form.Label
                    htmlFor="inputPassword5"
                    className="form-label mt-4"
                  >
                    Message
                  </Form.Label>
                 
                   <Form.Control
                        as="textarea"
                        placeholder=""
                        style={{ height: '100px' }}
                        className="form-input mt-1"
                        />
                </div>
                <form onSubmit={handleSubmit}>
        <div className="mt-4">
          {/* Add reCAPTCHA here */}
          <ReCAPTCHA
            sitekey="YOUR_SITE_KEY"
            onChange={handleRecaptcha}
          />
        </div>
       
      </form>
                
              </div>
              <button className="update-account mt-4">Submit Enquiry</button>
            </div>
          </div>
          <div className="col-md-4 contect-us-right mt-5">
          <div className="inner-shadow p-4">
            
            <h5>Address</h5>
            <p className="address-p">No 5, Trafford Road, Reading, RG1 8JP</p>

            <h5 className="pt-2">Contact Details</h5>
            <p className="address-p pt-1"><b>Phone:</b> 0118 958 8882</p>
            <p className="address-p"><b>Email:</b> online@getplumb.co.uk</p>

            <h5 className="pt-2">Opening Hours</h5>
            <p className="address-p pt-1">Monday - Friday: 8am - 6:00pm</p>
            <p className="address-p">Saturday: 8:00 am - 5:00 pm</p>
            <p className="address-p">Sunday: Closed</p>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
