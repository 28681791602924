import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

export default function AccordionTransition() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slotProps={{ transition: { timeout: 400 } }}
        sx={[
          expanded
            ? {
                '& .MuiAccordion-region': {
                  height: 'auto',
                },
                '& .MuiAccordionDetails-root': {
                  display: 'block',
                },
              }
            : {
                '& .MuiAccordion-region': {
                  height: 0,
                },
                '& .MuiAccordionDetails-root': {
                  display: 'none',
                },
              },
          {
            backgroundColor: '#EC1C25', // Set the background color to red
            color: 'white', // Set the text color to white
          },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Change icon color to white
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className='accordion-header'>How soon will my items be shipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Reading based discount plumbing, heating, bathrooms and spares supplies store with. We stock a huge range of boilers, plumbing bits, unvented cylinders and cylinders spares.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: '#EC1C25', // Set the background color to red
          color: 'white', // Set the text color to white
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Change icon color to white
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className='accordion-header'>How soon will my items be shipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: '#EC1C25', // Set the background color to red
          color: 'white', // Set the text color to white
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Change icon color to white
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className='accordion-header'>How soon will my items be shipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: '#EC1C25', // Set the background color to red
          color: 'white', // Set the text color to white
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Change icon color to white
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className='accordion-header'>How soon will my items be shipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: '#EC1C25', // Set the background color to red
          color: 'white', // Set the text color to white
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Change icon color to white
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className='accordion-header'>How soon will my items be shipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          backgroundColor: '#EC1C25', // Set the background color to red
          color: 'white', // Set the text color to white
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Change icon color to white
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className='accordion-header'>How soon will my items be shipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
