import React, { useState } from "react";
import Image from "../../Images/cartoon-3d-plumber-is-sitting-toilet-with-wrench-his-hand-he-is-wearing-blue-jumpsuit 1 (1).png";
import logo from "../../Images/Group 25 (1).svg";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Button, ButtonGroup, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values) => {
    console.log("Form data:", values);
    // Add your submit logic here
  };

  return (
    <div>
      <div className="row m-0">
        <div className="col-md-6 login-section-left">
          <div className="login-logo">
            <img src={logo} alt="" className="p-4" />
          </div>
          <div className="login-content p-5">
            <h5>Welcome to</h5>
            <h1>Get Plumb</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum standard dummy text
            </p>
          </div>
          <img src={Image} alt="" className="login-section-left-vector" />
        </div>

        <div className="col-md-6 login-right-section m-auto">
          <p className="p-4 login-right-section-p">
            Don’t have an account?{" "}
            <span>
              <b>
                <Link to="/login" className="forgot-password text-dark">
                  Sign in
                </Link>
              </b>
            </span>
          </p>

          <div className="px-5 mt-5 pt-4">
            <h3>
              <b>Sign up</b>
            </h3>
            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="row">
                  <div className="col-4 p-1">
                    <Link to="/sign-up">
                      <div className="line "></div>
                    </Link>
                  </div>
                  <div className="col-4 p-1">
                    <Link to="/security-information">
                      <div className="line line-selected"></div>
                    </Link>
                  </div>
                  <div className="col-4 p-1">
                    <Link to="/address-information">
                      <div className="line"></div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="pt-3">
              <b>Security Information</b>
            </h4>
          </div>

          <Formik
            initialValues={{ mobile: "", password: "" }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form className="ask-to-sign px-5 " onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mt-3">
                      <label htmlFor="mobile" className="form-label">
                        Password
                      </label>
                      <Field
                        type="password"
                        id="mobile"
                        name="mobile"
                        className="form-input mt-1"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="mt-3">
                      <label htmlFor="mobile" className="form-label">
                        Confirm Password
                      </label>
                      <Field
                        type="password"
                        id="phone"
                        name="phone"
                        className="form-input mt-1"
                      />
                    </div>
                  </div>
                </div>

                <Link to="/address-information">
                  <button className="sign-up-btn mt-4">Next</button>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
