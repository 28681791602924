import React, { useState } from "react";
import Image from "../../Images/cartoon-3d-plumber-is-sitting-toilet-with-wrench-his-hand-he-is-wearing-blue-jumpsuit 1 (1).png";
import logo from "../../Images/Group 25 (1).svg";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Button, ButtonGroup, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values) => {
    console.log("Form data:", values);
    // Add your submit logic here
  };

  return (
    <div>
      <div className="row m-0">
        <div className="col-md-6 login-section-left">
          <div className="login-logo">
            <img src={logo} alt="" className="p-4" />
          </div>
          <div className="login-content p-5">
            <h5>Welcome to</h5>
            <h1>Get Plumb</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum standard dummy text
            </p>
          </div>
          <img src={Image} alt="" className="login-section-left-vector" />
        </div>

        <div className="col-md-6 login-right-section m-auto">
          <p className="p-4 login-right-section-p">
            Don’t have an account?{" "}
            <span>
              <b>
                <Link to="/sign-up" className="forgot-password text-dark">
                  Sign up
                </Link>
              </b>
            </span>
          </p>

          <div className="px-5 mt-5 pt-4">
            <h3>
              <b>Login</b>
            </h3>
            <p>Welcome back! Please enter email and password</p>
          </div>

          <Formik
            initialValues={{ mobile: "", password: "" }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form className="ask-to-sign px-5 " onSubmit={handleSubmit}>
                <div className="mt-3">
                  <label htmlFor="mobile" className="form-label">
                    Phone Number / Email address
                  </label>
                  <Field
                    type="text"
                    id="mobile"
                    name="mobile"
                    className="form-input mt-1"
                  />
                </div>

                <div className="mt-3">
                  <label htmlFor="password" className="form-label">
                    Password{" "}
                    <span className="password-in-forget">Forget Password?</span>
                  </label>
                  <div className="password-input-container">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      className="form-input mt-1"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className="toggle-password-icon"
                    >
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                </div>

                <p className="continue-with mt-2">
                  <input type="checkbox" /> Remember this device
                </p>

                <ButtonGroup
                  variant="contained"
                  aria-label="outlined button group"
                  style={{
                    display: "flex",
                    background: "#EAEAEA",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "200px",
                  }}
                  className="sign-in-btn mt-2 mb-5"
                >
                  {/* Left Side: Login Button */}
                  <Button
                    style={{
                      color: "black",
                      background: "#EAEAEA",
                      border: "none",
                    }}
                  >
                    Login
                  </Button>

                  {/* Right Side: Red Arrow in a Box */}
                  <Link to="/">
                  <Box
                    sx={{
                      backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                    className="btn-box"
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ color: "white" }}
                    />
                   
                  </Box>
                  </Link>
                  
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
