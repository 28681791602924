import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home/Home";
import Listing from "./components/Listing";
import MyProfile from "./components/MyProfile";
import { Routes, Route } from "react-router-dom";
import Footer from './components/Footer/Index';
import ShopCatergory from './components/ShopCatergory/index'
import ProductDetails from './components/ProductDetails/index';
import Account from './components/Account/Index';
import AboutUs from './components/AboutUs/Index';
import ContactUs from './components/ContactUs/Index';
import Login from './components/LoginFlow/Login';
import SignUp from './components/LoginFlow/SignUp';
import SecurityInformation from './components/LoginFlow/SecurityInformation';
import AddressInformation from './components/LoginFlow/AddressInformation'

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" Component={Home} /> 
        <Route path="/listing" Component={Listing} />
        <Route path="/myprofile" Component={MyProfile} />
        <Route path="/footer" Component={Footer} />
        <Route path="/shop-catergory" Component={ShopCatergory} />
        <Route path="/product-details" Component={ProductDetails} />
        <Route path="/account" Component={Account} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/contact-us" Component={ContactUs} />
        <Route path="/login" Component={Login} />
        <Route path="/sign-up" Component={SignUp} />
        <Route path="/security-information" Component={SecurityInformation} />
        <Route path="/address-information" Component={AddressInformation} />
      </Routes>
    </>
  );
}

export default AppRoutes;
